import React, {useState} from 'react';
import Lottie from 'react-lottie';
import * as animationData from './speaker_anim_colors.json';
import {isMobile} from 'react-device-detect';
import ReactPlayer from 'react-player';

const defaultOptions = {
  loop: true,
  autoplay: false,
  animationData: animationData.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const Player = ({src}) => {
  const [isPlaying, setPlaying] = useState(false);
  return (
    <>
      <div onClick={() => setPlaying(!isPlaying)}>
        <ReactPlayer url={src} playing={isPlaying} width={0} height={0} />
        <Lottie
          options={defaultOptions}
          height={isMobile ? 25 : 50}
          width={isMobile ? 25 : 50}
          isStopped={!isPlaying}
        />
      </div>
    </>
  );
};

export default Player;
