import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 74vh;
  width: 100%;
  outline: none !important;

  model-viewer#reveal {
    --poster-color: #d3d3d3;
  }

  model-viewer {
    height: 100%;
    width: 100%;
    position: relative;
    outline: none !important;
    background: radial-gradient(
      circle at 50%,
      #c3c3c3,
      #c3c3c3 35%,
      #717171 130%
    );
  }
`;
