import React from 'react';
import Player from '../../atoms/Player/Player';
import {Container} from '../../global';
import {
  DescriptionWrapper,
  DescrptionTitle,
  Text,
  Title,
  TitleBar,
} from './styles';
import ReactHtmlParser from 'react-html-parser';

const ArtDescription = ({data}) => {
  const {title, text, audioSrc} = data;
  return (
    <>
      <DescrptionTitle>
        <Container padding>
          <TitleBar>
            <Title>{title}</Title>
            <Player src={audioSrc} />
          </TitleBar>
        </Container>
      </DescrptionTitle>
      <DescriptionWrapper>
        <Container padding>
          <Text>{ReactHtmlParser(text)}</Text>
        </Container>
      </DescriptionWrapper>
    </>
  );
};

export default ArtDescription;
