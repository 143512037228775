import styled from 'styled-components';

export const DescrptionTitle = styled.div`
  width: 100%;
  padding: 0;
  color: white;
  background: #525252;
  position: sticky;
  bottom: 0;
  margin-bottom: -1px; // ios fix
`;
export const DescriptionWrapper = styled.div`
  width: 100%;
  padding: 1rem 0;
  color: white;
  background: #525252;
  padding-top: 0;
  padding-bottom: 3rem;
`;

export const TitleBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 20;
`;

export const Title = styled.h3`
  font-family: 'LATOBLACK';
  font-size: 1.5rem;
  margin: 1.5rem 0;
`;

export const Text = styled.div`
  font-family: 'LATOREGULAR';
  font-size: 0.83rem;
  line-height: 1.7;
  text-align: justify;

  p {
    padding: 0.5em 0;
  }

  a {
    color: inherit;
  }
`;
