import React, {useEffect} from 'react';
import SEO from '../components/seo';
import ArtDescription from '../components/desktop/ArtDescription/ArtDescription';
import Gallery from '../components/desktop/Gallery/Gallery';
import Model3D from '../components/desktop/Model3D/Model3D';
import {Container} from '../components/global';
import {API_ASSETS_PREFIX} from '../globals';

const Art = ({pageContext}) => {
  const isPolish = pageContext.language === 'pl';

  useEffect(() => {
    document.querySelector('main').classList.add('hasStickyElement');
  }, [pageContext]);

  const getColor = () => {
    switch (pageContext.category) {
      case 1:
        return '#A4C0C0';
      case 2:
        return '#E19087';
      case 3:
        return '#E0C887';
      case 4:
        return '#AFA3C0';
    }
  };

  return (
    <>
      <SEO title="Art" />
      <Model3D
        modelSrcMobile={API_ASSETS_PREFIX + pageContext.models.mobile}
        modelSrc={API_ASSETS_PREFIX + pageContext.models.desktop}
      />
      <ArtDescription
        data={{
          title: isPolish ? pageContext.title.pl : pageContext.title.en,
          text: isPolish
            ? pageContext.description.pl
            : pageContext.description.en,
          audioSrc: API_ASSETS_PREFIX + pageContext.audio,
        }}
      />
      <div style={{background: getColor()}}>
        <Container padding>
          <Gallery
            images={pageContext.photos.map((src) => {
              return {src: API_ASSETS_PREFIX + src, sign: null};
            })}
            color={getColor()}
            columnsNumber={pageContext.photos.length % 3 === 0 ? 3 : 4}
          />
        </Container>
      </div>
    </>
  );
};

export default Art;
